.google_translate_element {
  /* width: 100px !important;
  height: 100px;
  border: 2px solid blue !important; */

  /* position: fixed !important;
  top: 50px !important;
  left: 100px !important; */
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.363);
  border-radius: 5px;
  padding: 0px;
  clip-path: polygon(0 0, 100% 0, 100% 25px, 0 25px);
  z-index: 99;
  cursor: pointer;
  /* border: 2px solid white !important; */
}

.borderBox {
  position: absolute;
  top: 0;
  /* left: -2.5%; */
  left: -5%;

  width: 110%;
  height: 25px;
  background: linear-gradient(-90deg, #4b65dc, #ed4264);
}

#box {
  position: relative;
}

.box {
  font-size: 20px !important;
}
