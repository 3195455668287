@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Proxima Nova";
  src: url("../public/ProximaNova-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../public/Proxima-Nova-Bold.ttf");
  font-weight: 700;
}

body {
  margin: 0;
  top: 0px !important;
  position: static !important;
}

::selection {
  color: whitesmoke;
  /* background: linear-gradient(90deg, #4b66dcb4, #ed4264b4); */

  background: rgba(0, 34, 255, 0.816);
}
::-moz-selection {
  color: rgba(50, 47, 255, 0.93);

  background: rgba(0, 34, 255, 0.816);
}

:root {
  /* fonts */
  --Proxima: "Proxima Nova";
  --font-proxima-nova: "Proxima Nova";
  --font-open-sans: "Open Sans";

  /* font sizes */
  --font-size-8xs: 9px;
  --font-size-7xs: 13px;
  --font-size-6xs: 14px;
  --font-size-5xs: 15px;
  --font-size-4xs: 16px;
  --font-size-3xs: 18px;
  --font-size-2xs: 20px;
  --font-size-xs: 22px;
  --font-size-sm: 24px;
  --font-size-base: 25px;
  --font-size-lg: 30px;
  --font-size-xl: 32px;
  --font-size-2xl: 35px;
  --font-size-3xl: 40px;
  --font-size-4xl: 45px;
  --font-size-5xl: 70px;
  --font-size-6xl: 80px;

  /* Colors */
  --textColor: #333;
  --textColor2: #333333;
  --fff: #fff;
  --333: #333;
  --color-white: #fff;
  --color-gray-100: #f6f7f8;
  --color-gray-200: #999999;
  --color-gray-300: #6f6f6f;
  --color-gray-400: #333;
  --color-gray-500: rgba(255, 255, 255, 0.7);
  --color-gray-600: rgba(255, 255, 255, 0.9);
  --color-gray-700: rgba(255, 255, 255, 0.85);
  --color-gray-800: rgba(45, 45, 45, 0.7);
  --color-gray-900: rgba(0, 0, 0, 0.1);
  --color-red-100: #fe0000;
  --color-red-200: rgba(254, 0, 0, 0.75);
  --color-red-300: rgba(254, 0, 0, 0.7);
  --color-red-400: rgba(254, 0, 0, 0.65);
  --color-turquoise: rgba(15, 205, 177, 0.68);

  /* --color-gray-100: #333; */
  --color-purple-100: #b90c78;
  --color-purple-200: #6c2382;
  --color-purple-300: #95065f;
  --color-purple-400: #521065;
  --color-orange-100: #f1a446;
  --color-orange-200: #e6932e;
  --color-indigo-100: #347ff6;
  --color-indigo-200: #1c6ae4;
  --color-black: #000;

  /* Gaps */
  --gap-4xs: 2px;
  --gap-3xs: 5px;
  --gap-2xs: 9px;
  --gap-xs: 10px;
  --gap-sm: 11px;
  --gap-md: 15px;
  --gap-lg: 19px;
  --gap-xl: 20px;
  --gap-2xl: 25px;
  --gap-3xl: 30px;
  --gap35: 35px;
  --gap-4xl: 40px;
  --gap-5xl: 50px;
  --gap-6xl: 70px;

  /* Paddings */
  --padding-6xs: 1px;
  --padding-5xs: 2px;
  --padding-4xs: 5px;
  --padding-3xs: 9.453125px;
  --padding-2xs: 10px;
  --padding-xs: 11px;
  --padding-sm: 13px;
  --padding-md: 14px;
  --padding-lg: 15px;
  --padding-xl: 20px;
  --padding-2xl: 30px;
  --padding-3xl: 40px;
  --padding-4xl: 50px;
  --padding-5xl: 60px;
  --padding-6xl: 80px;

  /* border radiuses */
  --br-xs: 4px;
  --br-sm: 5px;
  --br-md: 14.5px;
  --br-lg: 14px;

  /* border */
  --border: 1px solid black;

  /* margin-top */
  --margintop: 90px;

  /* horizontal spacing */
  --spacing: 0 12%;
}
* {
  box-sizing: border-box;
  /* background-color: rgba(0, 128, 0, 0.451); */
}
hr {
  height: 2px;
  background-color: #707070;
}
input:focus {
  outline: none;
}
input::placeholder {
  font-family: var(--font-proxima-nova);
  color: #d8d9da;
}
textarea:focus {
  outline: none;
}
textarea::placeholder {
  font-family: var(--font-proxima-nova);
  color: #d8d9da;
}
button:disabled {
  background-color: #cccccc !important;
}
button:disabled:hover {
  background-color: #cccccc !important;
}
Link,
a {
  text-decoration: none;
}
.link {
  text-decoration: none;
}
button {
  background: linear-gradient(90deg, #4b66dc, #ed4264);
  transition: 0.3s linear;
  font-size: 16px !important;
  /* height: 50px; */
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
  line-height: 1.5 !important;
}
button:hover {
  scale: 0.97;
  background: linear-gradient(270deg, #4b66dce5, #ed4264de);
}

html {
  scroll-behavior: smooth;
  zoom: 0.8;
}

/* .goog-te-banner-frame {
  display: none !important;
} */

.skiptranslate .skiptranslate {
  display: none !important;
  height: 0px !important;
}

/* #goog-gt-tt {
  display: none !important;
} */

.VIpgJd-suEOdc {
  display: none !important;
}

/* .VIpgJd-yAWNEb-nVMfcd-fmcmS VIpgJd-yAWNEb-hvhgNd-axAV1 */

@media screen and (max-width: 600px) {
  html {
    zoom: 1;
  }
}

/* .btn {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-5xl);
  border-radius: var(--br-sm);
  width: 100px;
  height: 40px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
  align-self: self-end;
} */
