.box {
  /* background: linear-gradient(90deg, #4b66dcb4, #ed4264b4); */
  /* background: #ed426493; */
  /* padding: 0 50px 20px; */
  border-radius: 5px;
  width: max-content;
  position: relative;
}
.checkbox {
  border-radius: 50%;
}
.text {
  font-size: 18px;
  color: var(--color-gray-600);
  padding-right: 5px;
  cursor: pointer;
}
.arrow {
  background-color: white !important;
  border-radius: 50%;
  rotate: -180deg;
}
.otherLangBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}
.otherLangBox:hover {
  padding-bottom: 0px;
}
.otherLangBox:hover .GTranslate {
  display: block;
  translate: 0 0%;
}
.innerBox {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.GTranslate {
  /* position: absolute; */
  /* left: 25px; */
  /* bottom: 0; */
  /* top: 35px; */
  translate: 0 -50%;
  display: none;
  transition: 2s;
}


