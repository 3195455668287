.pageNumberTopBox {
  overflow: hidden;
  margin: 50px 0 50px;
  display: flex;
  flex-direction: row;
}
.pageNumberBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  row-gap: 10px;
  column-gap: 10px;
  /* justify-content: center; */
  overflow-x: scroll;
  /* overflow-y: scroll; */
}
.pageNumber {
  padding: 3px 10px;
  color: blue;
  /* border: 1px solid rgba(0, 255, 51, 0.742); */
  border: 2px solid violet;
  /* border-radius: 50%; */
  margin: 0 3px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  scale: 0.8;
  transition: 0.2s linear;

  /* aspect-ratio: 1/1; */
}
.pageNumber:hover {
  border: 2px solid rgba(0, 255, 51, 0.642);
  scale: 1;
}

.active {
  /* border: 2px solid blue; */
  border: 2px solid violet;
  background: linear-gradient(90deg, #4b66dc83, #ed426488);
  /* rgba(238, 130, 238, 0.5); */

  scale: 1;
}
