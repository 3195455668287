.notFound {
  margin: 100px 0;
}

.searchResult {
  margin: 0px 10% 50px;
  padding: 0 10%;
  /* border: 2px solid red; */
  width: 100%;
  /* rotate: 40deg; */
}
.pagination {
  display: flex;
  justify-content: center;
}

/* .home {
  position: relative;
} */

.translateButtonBox {
  /* position: fixed !important;
  top: 150px !important;
  right: 50px; */

  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.363);
  border-radius: 5px;
  padding: 5px;
}
